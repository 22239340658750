import "./error.scss"

import { Link } from 'gatsby'
import React from "react"
import honeyGears from "../../../static/images/error/honey-gears.svg"
import { getStaticImageByIdentifier } from "../../services/image.service";
import { getStaticContentByIdentifier } from "../../services/misc.service";
import { getMailLink  } from "../../services/mail.service";

export default (props) => {
  return (
    <section className="margin-t-0-xs margin-t-6-md margin-b-6">
      <div className="hero row middle-md margin-t-2-xs margin-t-6-md mobile-reverse">
      <div className="col-xs-12 col-md-6 text-center o1">
            <h1>{getStaticContentByIdentifier('E500.title', props.staticContent)}</h1>
            <p>{getMailLink(getStaticContentByIdentifier('E500.content', props.staticContent))}</p>
            <p className="error-text">Error Code: 500</p>
            <Link to="/">
              <button className="error-button">{getStaticContentByIdentifier('Error.button', props.staticContent)}</button>
            </Link>
        </div>
        <div className="col-xs-12 col-md-6 img-col o2">
            <img className="error-illustration" alt="error message" src={getStaticImageByIdentifier('E500.image', props.staticImages)} />
        </div>
      </div>
    </section>
  )
}