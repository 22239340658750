import "./error.scss"

import { Link } from "gatsby"
import React from "react"
import obLogo from '../../../static/images/orange-bees-logo-dark.svg'

export default () => {
  return (
    <header className="ob-error-header row-xs-6">
      <a id="skip-nav" className="ob-header-skip-content btn-secondary" href="#maincontent">
        Skip to main content
      </a>
      <div className="row">
        <div className="col-xs-4 col-md-3 col-gutter-lr">
          <Link to="/">
            <img
              className="ob-error-header-logo"
              src={obLogo}
              alt="Orange Bees"
            />
          </Link>
        </div>
      </div>
    </header>
  )

}