import React from "react";

/*
  Inputs:   A string containing a single mail link
  Outputs:  A React component containing the original text with the mail address made into a link

  Note: Limited to text with a single mail link, should probably update to handle multiple links
*/

const getMailLink = (string) => {
  console.log(string);
  const ad = string.search(/(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/);
  console.log(ad);
  // Extract address by finding next space
  let addArray = (string.substring(ad)).split(' ');
  let addr = addArray[0];
  // Check if the address ends with a period instead
  addr = addr[addr.length-1] == '.' ? addr.substring(0, addr.length-1) : addr;
  // Check for comma as well
  addr = addr[addr.length-1] == ',' ? addr.substring(0, addr.length-1) : addr;
  console.log(addr[addr.length-1]);
  
  // Split the string by the 
  const array = string.split(addr);
  console.log(array);
  const reftext = "mailto:"+addr;
  return array[0] ? <>{array[0]}<a href={reftext} className="mail-link">{addr}</a>{array[1]}</> : '';
};

export { getMailLink };